import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';
import { formatDateForAPI } from '@/util/apiDateFormat';
import { DEMO_ADVERTISERS, KEY_DEMO_ADVERTISER_ID } from '@/constant';
// import { isDemoInstance, getCampaignDateRange } from '@/util/utility-functions';
import { isDemoInstance } from '@/util/utility-functions';
import appConsoleDataLayer from '~/api/app-console-data';
import store from '~/store';

const computed = {
  dates: get('dashboard/dates'),
};

const methods = {
  async fetchCampaignOptions(solutionId) {
    try {
      if (
        !this.account ||
        !this.advertiser ||
        isBlank(this.account.name) ||
        isBlank(this.advertiser.name)
      ) {
        this.$store.set('dashboard/filters@campaignOptions', []);
        this.$store.set('dashboard/filters@adGroupOptions', []);
        return;
      }
      const advertiserId = this.advertiser ? this.advertiser.id : 0;
      const advertiserName = this.advertiser ? this.advertiser.name : '';
      const accountName = this.account ? this.account.name : '';
      const hasXandrAdvertiserId = this.advertiser?.xandrAdvertiserId !== null;
      const campaigns = await appConsoleDataLayer.fetchCampaignOptions(
        advertiserId,
        advertiserName,
        accountName,
        solutionId,
        hasXandrAdvertiserId
      );

      if (!campaigns.length) {
        this.$store.set('dashboard/filters@campaignOptions', []);
        this.$store.set('dashboard/filters@adGroupOptions', []);
        return;
      }

      // Mark recent campaigns as default checked
      const advertiserKeys = Object.keys(DEMO_ADVERTISERS).map((k) => parseInt(k, 10));
      const demoAdvertiserId = parseInt(localStorage.getItem(KEY_DEMO_ADVERTISER_ID), 10);
      const demoAdvertiserMapping = advertiserKeys?.includes(demoAdvertiserId)
        ? DEMO_ADVERTISERS[demoAdvertiserId]
        : DEMO_ADVERTISERS[0];
      // Mark recent campaigns as default checked
      for (let i = 0; i < campaigns.length; i++) {
        if (isDemoInstance()) {
          if (demoAdvertiserMapping?.selectedCampaign?.includes(campaigns[i].value)) {
            campaigns[i].checked = true;
          }
        } else {
          campaigns[i].checked = campaigns[i].isRecent;
        }
      }

      // let dateRange = getCampaignDateRange(campaigns, true);
      let dateRange = store.get('dashboard/dates');
      if (isDemoInstance()) {
        dateRange = store.get('dashboard/dates');
      }

      // this.$store.set('dashboard/dates', {
      //   ...this.dates,
      //   ...dateRange,
      // });
      if (campaigns.length > 1) {
        this.fetchAndSetAdGroupOptions(solutionId, campaigns);
      }
      if (dateRange.notAvailable) {
        this.$store.set('dashboard/filters@mediaTypeOptions', []);
        this.$store.set('dashboard/filters@audienceOptions', []);
        this.$store.set('dashboard/filters@creativeOptions', []);
      } else {
        const payload = {
          advertiser: this.advertiser ? this.advertiser.name : '',
          client: this.account ? this.account.name : '',
          campaigns,
          adGroups: '',
          startDate: formatDateForAPI(dateRange?.startDate),
          endDate: formatDateForAPI(dateRange?.endDate),
        };
        this.fetchMediaTypes(advertiserId, solutionId, payload);
        this.fetchAudiences(advertiserId, solutionId, payload);
        this.fetchCreatives(advertiserId, solutionId, payload);
        this.fetchKeywords(advertiserId, solutionId, payload);
      }
    } catch (err) {
      this.$store.set('dashboard/filters@campaignOptions', []);
      this.$store.set('dashboard/filters@adGroupOptions', []);
      console.error('error fetching ios ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
      throw err;
    }
  },
  async fetchAndSetAdGroupOptions(solutionId, campaigns) {
    const advertiserId = this.advertiser ? this.advertiser.id : 0;
    const adGroupData = await appConsoleDataLayer.fetchAdGroupOptions(
      advertiserId,
      solutionId,
      campaigns
    );
    if (adGroupData?.length > 0) {
      for (let i = 0; i < adGroupData.length; i++) {
        adGroupData[i].checked = true;
        adGroupData[i].value = adGroupData[i].name;
      }
      this.$store.set(
        'dashboard/filters@adGroupOptions',
        adGroupData?.length > 0 ? adGroupData : []
      );
    } else {
      this.$store.set('dashboard/filters@adGroupOptions', []);
    }
  },
  async fetchMediaTypes(advertiserId, solutionId, payload) {
    const data = await appConsoleDataLayer.fetchMediaTypeOptions(advertiserId, solutionId, payload);
    const newData = [];
    if (data.length !== 0) {
      for (let i = 0; i < data.length; i++) {
        newData.push({ value: data[i], checked: true });
      }
      this.$store.set('dashboard/filters@mediaTypeOptions', newData);
    } else {
      this.$store.set('dashboard/filters@mediaTypeOptions', []);
    }
  },
  async fetchAudiences(advertiserId, solutionId, payload) {
    const data = await appConsoleDataLayer.fetchAudienceOptions(advertiserId, solutionId, payload);
    const filterOptions = [];
    const keysSet = new Set();
    if (data.length > 0) {
      // filter out duplicates
      data?.forEach((d) => {
        if (!keysSet.has(d.key)) {
          keysSet.add(d.key);
          filterOptions.push({ key: d.value, value: d.key, checked: false });
        }
      });
      this.$store.set('dashboard/filters@audienceOptions', filterOptions);
    } else {
      this.$store.set('dashboard/filters@audienceOptions', []);
    }
  },
  async fetchCreatives(advertiserId, solutionId, payload) {
    const data = await appConsoleDataLayer.fetchCreativeOptions(advertiserId, solutionId, payload);
    const filterOptions = [];
    if (data.length) {
      // filter out objects with an empty value
      data
        ?.filter((d) => d.value !== '')
        .forEach((d) => filterOptions.push({ ...d, checked: false }));
      this.$store.set('dashboard/filters@creativeOptions', filterOptions);
    } else {
      this.$store.set('dashboard/filters@creativeOptions', []);
    }
  },
  async fetchKeywords(advertiserId, solutionId, payload) {
    const data = await appConsoleDataLayer.fetchKeywordOptions(advertiserId, solutionId, payload);
    const filterOptions = [];
    if (data.length) {
      data?.forEach((d) => filterOptions.push({ ...d, checked: false }));
      this.$store.set('dashboard/filters@keywordOptions', filterOptions);
    } else {
      this.$store.set('dashboard/filters@keywordOptions', []);
    }
  },
};

export default { methods, computed };
