import { formatDateForAPI } from '@/util/apiDateFormat';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';

const methods = {
  async fetchPublishers() {
    try {
      const data = await advertiserReportsAPI.publishers(
        this.advertiser ? this.advertiser.id : 0,
        buildQueryString({
          advertiser: this.advertiser ? this.advertiser.name : '',
          client: this.account ? this.account.name : '',
          endDate: formatDateForAPI(this.dates.endDate),
          startDate: formatDateForAPI(this.dates.startDate),
        })
      );
      const publisherOptions = [];
      if (data.length) {
        data?.forEach((d) => publisherOptions.push({ ...d, checked: false }));
      }
      this.$store.set('dashboard/filters@publisherOptions', publisherOptions);
      return;
    } catch (err) {
      console.error('error fetching publishers ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
      throw err;
    }
  },
};
export default { methods };
